body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content{
  width: 80%;
  margin: 3rem auto;
  min-height: 100vh;
  
}
.error{
  color: red;
  font-size: 1.5rem;
  font-weight: 400;
}

/*color palette*/
:root {
  --spotify-green: #1ED760;
  --spotify-black: #121212;
  --light-green: #3de176;
  --slate-gray: #6b818c;
  --main-black: #212121;
  --magnolia: #f5f0f6;
  --hollywood-cerise: #ef2ea2;
  --bc-black: #3d3d3d;
}

/*inter font family*/

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
} 

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

/* atma font family */

@font-face {
  font-family: 'atma';
  src: url('./assets/fonts/atma/Atma-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'atma';
  src: url('./assets/fonts/atma/Atma-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'atma';
  src: url('./assets/fonts/atma/Atma-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'atma';
  src: url('./assets/fonts/atma/Atma-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'atma';
  src: url('./assets/fonts/atma/Atma-Bold.ttf') format('truetype');
  font-weight: 700;
}

/* lora font family */

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'lora';
  src: url('./assets/fonts/lora/Lora-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

