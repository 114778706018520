.light {
    background-color: var(--magnolia);
    color: var(--main-black);
    animation: themeChange 0.5s ease-in-out;
}

.dark {
    background-color: var(--main-black);
    color: var(--magnolia);
    animation: darkThemeChange 0.5s ease-in-out;
}

@keyframes themeChange {
    0% {
        background-color: #333;
        color: #f5f5f5;
    }

    100% {
        background-color: #EEEEF2;
        color: #333;
    }
}

@keyframes darkThemeChange {
    0% {
        background-color: #EEEEF2;
        color: #333;
    }

    100% {
        background-color: #333;
        color: #f5f5f5;
    }
}
a {
    text-decoration: none;
    
}
a:visited {
    color: inherit;
}
    
