.footerContainer{
    width: 80%;
    margin: 0 auto;    
    padding: 0rem 1rem;
    padding-top: 3rem;
    overflow-x: hidden;
}
.ft-light{
    border-top: 1px solid var(--slate-gray);
    background-image: linear-gradient(var(--magnolia), #E5D9E8);
}
.ft-dark{
    border-top: 1px solid var(--slate-gray);
    background-image: linear-gradient(var(--main-black), #3d3d3d);
    
}
.footer-content{
    display: flex;
    justify-content: space-between;
}
.footer-links{
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;
}
.footer-copyright {
    display: flex;
    justify-content: space-between;

}

.footer{
    position: relative;
    width: 100%;
    bottom: 0;
    margin-top: 4rem;
    overflow-x: hidden;
}
.footer-links a{
    color: inherit;
    font-size: 1.3rem;
    transition: color 0.5s;
}
.footer-links a:visited{
    color: inherit;
}
.footer-links a:hover{
    color: var(--spotify-green);

}
.footer-contact h2 {
    font-size: 3rem;
    font-weight: 600;
    padding: 2rem;
    
    border-radius: 50%;
    transform: rotate(-20deg);
    transition: transform 0.5s;
    cursor: pointer;
}
.ft-title-light{
    border: 1px solid var(--main-black);
}
.ft-title-dark{
    border: 1px solid var(--magnolia);
}
.footer-icon{
    margin-left: 1rem;
}
.footer-nav h3 , .footer-social h3{
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
}
.footer-contact h2:hover{
    transform: rotate(0deg);
}
.footer-logo p {
    font-size: 3rem;
    margin: 0;
    font-weight: 600;
}
.footer-credits{
    font-size: 1.5rem;
    text-align: end;
}
.footer-credits-link {
    color: var(--hollywood-cerise);
    margin: 0 0.5rem;   
}

.footer-credits-link:visited {
    color: var(--hollywood-cerise);
}
@media screen and (max-width: 1200px){
    .footerContainer{
        width: 90%;
    }
}

@media screen and (max-width: 768px){
    .footer-content{
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }
    .footer-links{
        gap: 3rem;
        margin-bottom: 0;
    }
    .footer-contact h2 {
        font-size: 2rem;
        padding: 1rem;
        text-align: center;
        transform: rotate(15deg);
    }
    .footer-logo p {
        text-align: center;
        font-size: 2rem;
    }
    .footer-links a{
    font-size: 1.5rem;
    }
    .footer-copyright{
        flex-direction: column;
        
        align-items: center;
    }
    .footer-nav h3 , .footer-social h3{
        font-size: 1.2rem;
    }
    .footer-credits{
        font-size: 1.2rem;
        text-align: center;
    }
}