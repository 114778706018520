.navbar{
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem;
    font-family: 'inter';
    position: sticky;
    top: 0;
    z-index: 100;
    width: 95%;
    
}

.melty-face {
    width: 1.7rem;
    height: auto;
    margin-right: 0.5rem;
}

.logo{
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}
.logo h1{
    font-family: 'atma';
    font-size: 3rem;
    margin-left: 1rem;
    font-weight: 500;
    
    border-radius: 1rem;
}
.lg-dark{
    background-color: var(--main-black);
}
.lg-light{
    background-color: var(--magnolia);
}
.logo p {
    font-size: 1rem;   
}
.tagline{
    display: flex;
    background-color: #3d3d3d;
    padding: 0rem 0.75rem;
    border-radius: 1rem;
}
.tg-dark, .nl-dark{

    background-color: #3d3d3d;

}
.tg-light, .nl-light{
    background-color: #E5D9E8;
    
}
.nav-links{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.nl-dark a{
    color: var(--magnolia);
}
.nl-light a{
    color: var(--main-black);
}
.nav-links-container{
    padding: 0.5rem 1rem;
    gap: 1.2rem;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: 1rem;
}
.nav-links-container a:hover{
    color: var(--light-green);
    transition:ease-in-out 0.5s;
}
.get-started{
    color: inherit;
    background-color: var(--spotify-green);
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    font-size: 1.1rem;
    border: none;
    cursor: pointer;
}
.get-started:hover{
    background-color: var(--light-green);
    transition:ease-in-out 0.5s;
}
.theme-toggler, .nv-close-icon{
    font-size: 2rem;
    background-color: inherit;
}
.nv-close{
    display: none;
    animation: mobile-close-slide 0.5s ease-in-out;
}
.bars-icon{
    font-size: 2rem;
    margin-top: 2.5rem;
}
.nv-open{
    display: flex;
    animation: mobile-open-slide 0.5s ease-in-out;
}
.mobile-navbar-links{
    width: 70%;
    flex-direction: column;
    position: absolute;
    top: 2rem;
    right: 0;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 1rem;
    z-index: 100;
}
.mobile-navbar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.mobile-navbar-links a{
    color: inherit;
    font-size: 1.5rem;
}
.mobile-nv-dark {
    background-color: #3d3d3d;
}
.mobile-nv-light {
    background-color: #E5D9E8;
}
@keyframes mobile-open-slide {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0%);
    }
}
@keyframes mobile-close-slide {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(100%);
    }
}
@media screen and (max-width: 1200px) {
    
    .logo {
        
        align-items: flex-start;
        flex-direction: column;
       
    }
    .logo h1{
        font-size: 2.5rem;
        margin: 0;
    }
   .tagline{
    margin-top: -0.75rem;
    
   }
}
@media screen and (max-width: 768px){
    .navbar{
        padding: 0rem 1rem;
        position: relative;
    }
    .logo {
        margin-top: 1rem;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
    }
    .logo h1{
        font-size: 2.2rem;
        margin: 0;
    }
    .melty-face{
        width: 1.5rem;
    }
    .tagline p{
        
        font-size: 0.75rem;
    }
    .tagline{
        padding: 0.25rem 0.5rem;
        margin-top: -0.75rem;
        position: relative;
    }
    .bars-icon{
        margin-top: 1.9rem;
    }

}